.hostingPackage {
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 6px;
}

.hostingPackageTitle {
    /* position: absolute; */
    top: 0px;
    left: 0px;
    right: 0px;
    background-image: url('../../images/design/section_pattern.png'), 
    -webkit-linear-gradient(top, #050e7f 0%,#2f57a4 100%);
    clip-path: ellipse(100% 70% at 50% 0%);
    height: 9.5rem;
    z-index: 0;
}
.features .img-fluid{
    height: 100px !important;
} 
 
.hostingPackageTitle h5 {
    color: white !important;
    padding: 10px;
    text-align: center;
}

[data-cc-theme*="dark"] .hostingPackage {
    border: 1px solid white;
    border-radius: 5px;
}

.pageTextBreak {
    background-color: #2f57a4;
    background-image: url('../../images/design/section_pattern.png'), 
    -webkit-linear-gradient(top, #050e7f 0%,#2f57a4 100%);}

.imageTextBreak {
    height: 150px !important;
    margin-left: 50px !important;
}

.pageTextBreakBox {
    padding: 50px;
    color: white !important;

}
.pageTextBreakBox h6,h3{
    color: white;
}