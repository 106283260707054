
.price,
.price-style-01 {
    width: 100%;
}

.price p,
.price span,
.one-price p,
.one-price span,
.one-price li {
    text-align: start;
}

.price ul {
    width: 100%;
    max-width: max-content;
    margin: 0 auto;
}

.price li {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: start;
}

.price .content-img {
    width: 24px;
    margin-right: 6px;
}

.price-style-01 .card-header span,
.card-header span i {
    display: flex;
    justify-content: center;
    align-items: center;
}

.price-style-01 li {
    text-align: start;
}

.price .card-header .content-img {
    margin: unset;
    margin-left: 6px;
}

.price li i,
.price li strong,
.price span i,
.one-price li i {
    margin-right: 8px;
}

.price .text-muted {
    font-size: 20px;
}

.btn-outline-secondary {
    color: var(--text-color-black-lighter);
}

.price .btn-primary,
.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #0652DD;
    border-color: #0652DD;
}

.price .btn-primary:hover {
    background-color: #054bcc;
}

.price .btn-primary:active {
    background-color: #0343bb;
}

.price .card {
    margin: 7px;
}

.price .card-header {
    padding: 18px;
    background-color: transparent;
}

.price > [class*="col"] {
    padding: unset;
}

.one-price .card-deck {
    width: 80%;
}

.price-modern {
    padding: 30px;
    box-shadow: var(--box-shadow-one);
    display: flex;
    align-items: center;
}

.price-modern > [class*="col"],
.card-deck .card {
    padding: 10px;
}

.price-modern > [class*="col"] .card-header {
    margin-top: 20px;
}

.price-modern .card-body {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.price-modern ul {
    margin: unset;
}

.price-modern ul li {
    display: flex;
    align-items: center;
    margin-top: 7px;
    margin-bottom: 7px;
}

.price-modern ul li i{
    margin-right: 6px !important;
    color: green;
    padding: 5px;

}

.price-modern ul li span {
   padding-left: 5px !important;
}
.features .content-img {
    width: 90px;
    margin: 0 auto;
}
.price-modern .btn {
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    border-radius: 2px;
}

.price-modern .card-header {
    border: unset;
}

.price-ft {
    margin-top: 10px;
    margin-bottom: 10px !important;
}
