.supportBoxes {
    margin-top: -20px;
}

.supportBox {
    border-radius: 5px;
    /* background-color: #fff; */
    transition: .5s;
}

.supportBox:hover {
    transform: scale(1.04);
    box-shadow: 0 6px 30px -5px rgb(0 0 0 / 20%);
    transition: .5s;
    cursor: pointer;

}
.supportBox{
    box-shadow: 0 6px 20px -10px rgb(0 0 0 / 20%);

}
.searchFAQ {
    padding: 15px;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 6px 20px -10px rgb(0 0 0 / 20%);
}

#faqSearch{
    overflow: hidden;
    resize: none;
    background-color: #f5f4ef;
    border-color: #f5f4ef;
    color: #4d5865;
    border-radius: 0 0.3125rem 0.3125rem 0;
    height: 3.125rem;
    padding: 0.8rem;
    white-space: nowrap;
    /* max-width: 78%; */
}
.faqList hr{
    width: 20%;
    background-color: #2f57a4;
    opacity: 1;
    color: #2f57a4;
    padding: 2px;
    margin: 0px 0px 10px 0px;
    border-color: #2f57a4;
}
.faqListItem:hover{
    font-weight: 500;
    /* border-bottom: 2px solid #2f57a4; */
    cursor: pointer;
}