.categoryList li {
    margin: 5px;
}
.categoryList li span:hover {
    font-weight: 500;
    border-bottom: 1px solid #2f57a4;
    cursor: pointer;
}
.reviewIcon:hover{
    color: lightblue;
}
.reviewIcon:active{
    color: lightblue;
}