.controlPanelPreview {
    margin-top: -20px;
    position: relative;
}
.controlPanelPreview img{
    border-radius: 5px;
    box-shadow: 0 6px 30px -5px rgb(0 0 0 / 20%);

}
.controlPanelPreview .phonePreview {
    position: absolute;
    height: 80%;
    margin-top: 85px;
    margin-left: -10% !important;
    padding-bottom: 10px;
    border-radius: 5px;

}