html,
body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  width: 100%;
}



body {
  background: #fff;
  font-size: 14px;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  min-height: 100%;
  display: flex;
  color: #444;
  overflow-x: hidden;
  flex-direction: column;
  align-items: stretch;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
}

.header-bg-color {
  background-color: #f4f7ff !important;
}

.aos-init:not(.aos-animate):after {
  position: fixed;
}

body::-webkit-scrollbar {
  width: 11px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 6px;
  border: 3px solid var(--scrollbar-bg);
}


a {
  text-decoration: none;
}

.color-primary {
  color: #2f57a4;
}

.btn-centnodes {
  background-color: #2f57a4 !important;
  border-radius: 4px;
  color: white;
}

.btn-centnodes:hover {
  background-color: #957adf;
  color: white;
}

.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 30px rgb(1 41 112 / 10%);
}

.card-title {
  padding: 10px 0 5px;
  font-size: 18px;
  font-weight: 500;
  /* color: #444; */
  font-family: poppins, sans-serif
}

.page-title {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 400px;
  color: #2f57a4;
}

.breadcrumb {
  color: #899bbd;
  transition: .3s;
  display: inline;
  text-decoration: none;
  font-size: 14px;
}

.breadcrumb:nth-child(2) {
  color: #1f345a;
}

.toolBar {
  padding: 10px;
  border-radius: 5px;
  z-index: 100000;
  position: fixed;
  color: white;
  right: 20px;
  bottom: 50px;
  background-color: #2f57a4;
}

.toolBar svg:hover {
  color: rgb(64, 61, 61);
  transform: scale(1.3);
  transition: .75;
}

.toolBar svg {
  padding: 5px;
  transition: 0.5s
}

.centToast {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.fade-text {
  /* background-color: #2f57a4; */
  background: linear-gradient(43deg, #2f57a4 0%, #C850C0 46%, #FFCC70 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

[data-cc-theme*="dark"] .navbar-toggler svg {
  color: white !important;
}

.hero {
  background-image: url('../src/images/slider.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  height: 325px;

}

.hero-short {
  background-image: url('./images/design/section_pattern.png'),
    -webkit-linear-gradient(top, #050e7f 0%, #2f57a4 100%);
  color: white;
  height: 175px;

}

.hero-secondary {
  background-image: url('./images/design/section_pattern.png'),
    -webkit-linear-gradient(top, #050e7f 0%, #2f57a4 100%);
  color: white;
  height: 300px;

}

@media only screen and (max-width: 768px) {

  .hero {
    height: 250px !important;
  }
}

.color-centnodes {
  color: #2f57a4 !important;
}

.actionMenuItem {
  color: black;
}

.actionMenuItem span {
  color: #2f57a4;
}

.btn-outline-centnodes {
  color: #2f57a4;
  border-color: #2f57a4;
  border-radius: 4px;
  background-color: white;
}

.btn-outline-centnodes:hover {
  color: #fff;
  background-color: #2f57a4;
  border-color: #2f57a4;
}

.btn-outline-centnodes:active {
  color: #fff;
  background-color: #2f57a4;
  border-color: #2f57a4;
}

.nav-tabs .nav-item .active {
  border: 0px;
  border-bottom: 2px solid #2f57a4 !important;

}

.searchFAQ {
  background-image: linear-gradient(-90deg, #2f57a4 0%, #2f57a4 100%);
}

.searchFAQ button {
  background-color: white !important;
  color: #2f57a4;
}

.searchFAQ button:hover {
  background-color: #2f57a4 !important;
  color: white;
}

@media only screen and (max-width: 460px) {
  .searchFAQ button {
    padding: 10px;
    font-size: 14px;
  }
}


.backToTop {
  position: fixed;
  bottom: -10px;
  left: 15px;
  z-index: 10;

}

.backToTop .backToTopIcon {
  background-color: #2f57a4;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid white;
}

.backToTop .backToTopIcon:hover {
  background-color: #2f57a4;
  cursor: pointer;
}

:root {
  --header-one-bg: #fff;
  --header-two-bg: #fff;
  --header-one-color: #383333;
  --header-two-color: #383333;
  --header-one-hover-color: #1e3799;
  --header-one-active-color: #0d2894;
  --header-two-hover-color: #1e3799;
  --header-two-active-color: #0d2894;
  --thumb-bg-one: #fff;
  --thumb-text-one: black;
  --bg-light: #f5f6f891;
  --bg-grey: #f4f8fb;
  --light-color: #383333;
  --light-hover: #213daf;
  --light-active: #0d2894;
  --bg-dark: #0b163f;
  --dark-color: #fff;
  --dark-hover: #079992;
  --dark-active: #08807a;
  --navbar-responsive-bg: #30336b;
  --border-style-one: 1px solid #e8e8ef;
  --border-style-two: 1px solid #e5e5ee;
  --border-style-three: 1px solid #ededf4;
  --text-muted-color: #697485;
  --text-main-color: #142149;
  --main-hover: #1e3799;
  --main-active: #0d2894;
  --text-dark-one: black;
  --text-dark-two: #28446b;
  --text-dark-three: #0b163f;
  --hover-color-style-two: #079992;
  --active-color-style-two: #08807a;
  --hover-color-style-three: #fff;
  --active-color-style-three: #fff;
  --btn-primary-color: #2f57a4;
  --btn-primary-hover: #236aec;
  --btn-primary-active: #1b4696;
  --bs-font-sans-serif: "Open Sans", sans-serif;
  --link-color: #0e69ef;
  --link-hover: #0c55c2;
  --link-active: #063d91;
  --text-success: rgb(24 126 79);
  --text-black: #000;
  --text-blue: #266eda;
  --text-blue-dark: #0b3585;
  --text-indigo: #6610f2;
  --text-purple: #6f42c1;
  --text-pink: #6f42c1;
  --text-red: #dc3545;
  --text-orange: #fd7e14;
  --text-yellow: #ffc107;
  --text-green: #1db46e;
  --text-teal: #16af81;
  --text-cyan: #09aaca;
  --text-grey: rgb(155, 155, 155);
  --bg-blue-light: #0d6dfd2c;
  --bg-indigo-light: #6710f22c;
  --bg-purple-light: #6e42c134;
  --bg-pink-light: #6335b934;
  --bg-red-light: #dc35461f;
  --bg-orange-light: #fd7d1428;
  --bg-yellow-light: #ffc10728;
  --bg-green-light: #1db46e38;
  --bg-teal-light: #20c9962f;
  --bg-cyan-light: #0dcaf033;
  --bg-grey-light: rgba(255, 255, 255, 0.1);
  --bg-white: #fff;
  --scrollbar-bg: #CFD8DC;
  --scrollbar-thumb: #90A4AE;
}

/* ENDING ROOT VARIABLES */

/* STARTING DEFAULT FONTS */

@font-face {
  font-family: "font-light";
  font-display: swap;
  src: url("../src/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "font-regular";
  font-display: swap;
  src: url("../src/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "font-medium";
  font-display: swap;
  src: url("../src/fonts/Poppins-Medium.ttf");
}

/* ENDING DEFAULT FONTS */

/* STARTING THEMES */

/* Dark Theme */
[data-cc-theme*="dark"] {
  --header-one-bg: #00111c;
  --header-two-bg: #00111c;
  --header-one-color: #fff;
  --header-two-color: #fff;
  --header-one-hover-color: #2196f3;
  --header-one-active-color: #1d80d1;
  --header-two-hover-color: #2196f3;
  --header-two-active-color: #1d80d1;
  --thumb-bg-one: #00111c;
  --thumb-text-one: #fff;
  --bg-light: #011725;
  --bg-grey: #031c2b;
  --light-color: #fff;
  --light-hover: #2196f3;
  --light-active: #1d80d1;
  --bg-dark: #0b163f;
  --dark-color: #fff;
  --dark-hover: #079992;
  --dark-active: #08807a;
  --navbar-responsive-bg: #001a2c;
  --border-style-one: 1px solid #e8e8ef;
  --border-style-two: 1px solid #e5e5ee;
  --border-style-three: 1px solid #ededf4;
  --text-muted-color: #6e7f99;
  --text-main-color: #fff;
  --main-hover: #2196f3;
  --main-active: #1d80d1;
  --text-dark-one: #fff;
  --text-dark-two: #28446b;
  --text-dark-three: #fff;
  --hover-color-style-two: #079992;
  --active-color-style-two: #08807a;
  --hover-color-style-three: #6ab04c;
  --active-color-style-three: #49862f;
  --btn-primary-color: #2f57a4;
  --btn-primary-hover: #3767c0;
  --btn-primary-active: #1b4085;
  --link-color: #599afb;
  --link-hover: #3277dd;
  --link-active: #1958b8;
  --text-success: #35a371;
  --text-black: #fff;
  --text-blue: #2a7cf7;
  --text-blue-dark: #0b3585;
  --text-indigo: #6610f2;
  --text-purple: #6f42c1;
  --text-pink: #6f42c1;
  --text-red: #dc3545;
  --text-orange: #fd7e14;
  --text-yellow: #ffc107;
  --text-green: #1db46e;
  --text-teal: #16af81;
  --text-cyan: #09aaca;
  --text-grey: rgb(155, 155, 155);
  --bg-blue-light: #4f8ae15e;
  --bg-indigo-light: #6710f22c;
  --bg-purple-light: #6e42c134;
  --bg-pink-light: #6335b934;
  --bg-red-light: #dc354652;
  --bg-orange-light: #fd7d1428;
  --bg-yellow-light: #ffc10728;
  --bg-green-light: #1db46e38;
  --bg-teal-light: #20c9962f;
  --bg-cyan-light: #0dcaf033;
  --bg-grey-light: rgba(255, 255, 255, 0.1);
  --bg-white: #00111c;
  --bs-list-group-bg: #00111c;
  --scrollbar-bg: #CFD8DC;
  --scrollbar-thumb: #90A4AE;
}

/* [data-cc-theme*="dark"] textarea, */
[data-cc-theme*="dark"],
[data-cc-theme*="dark"] .accordion-flush .accordion-item .accordion-button,
[data-cc-theme*="dark"] .accordion-body,
[data-cc-theme*="dark"] .main-timeline .timeline-icon:after {
  background-color: var(--thumb-bg-one);
}

[data-cc-theme*="dark"] .card,
[data-cc-theme*="dark"] #dashboardNavigation {
  background-color: #011725 !important;
  border-bottom: 1px solid white;
}

#dashboardNavigation {
  background-color: #fff;
}

[data-cc-theme*="dark"] #sidebar {
  background-color: #011725;
  border-right: 1px solid white;
}

[data-cc-theme*="dark"] .sidebar-nav .nav-link.collapse {
  background-color: #031c2b !important;
}

[data-cc-theme*="dark"] .main-timeline:before {
  background-color: var(--bg-light);
}

[data-cc-theme*="dark"] .usernameNav {
  color: white;
}

[data-cc-theme*="dark"] .accordion-body,
[data-cc-theme*="dark"] .accordion-flush .accordion-item .accordion-button,
[data-cc-theme*="dark"] .main-timeline .description {
  color: var(--text-main-color);
}

[data-cc-theme*="dark"] .bg-white {
  background-color: var(--bg-white) !important;
}

[data-cc-theme*="dark"] .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

[data-cc-theme*="dark"] .btn-outline-transparent {
  color: var(--text-main-color);
}

[data-cc-theme*="dark"] .btn-outline-transparent:hover {
  border-color: var(--text-main-color);
}

[data-cc-theme*="dark"] .input-group button {
  border: 1px solid var(--text-main-color) !important;
}

[data-cc-theme*="dark"] .dropdown-menu {
  background-color: var(--bg-white);
}

[data-cc-theme*="dark"] .list-group-item,
[data-cc-theme*="dark"] .btn-outline-primary,
[data-cc-theme*="dark"] .btn-outline-skyblue {
  border-color: var(--text-main-color);
  color: var(--text-main-color);
}

[data-cc-theme*="dark"] .pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--text-main-color);
  --bs-pagination-bg: var(--bg-light);
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--text-main-color);
  --bs-pagination-hover-bg: var(--bg-dark);
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--text-main-color);
  --bs-pagination-focus-bg: var(--bg-dark);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25remrgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: var(--bg-dark);
  --bs-pagination-active-border-color: var(--bg-dark);
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
}

::placeholder {
  color: #bec9d4 !important;
}

[data-cc-theme*="dark"] select,
[data-cc-theme*="dark"] select option {
  background-color: var(--bg-white);
  color: var(--text-dark-three);
}

[data-cc-theme*="dark"] input,
[data-cc-theme*="dark"] textarea {
  color: #444 !important;
}

[data-cc-theme*="dark"] input:focus,
[data-cc-theme*="dark"] textarea:focus,
[data-cc-theme*="dark"] select:focus {
  /* background-color: var(--bg-blue-light) !important; */
  color: #444 !important;
}

[data-cc-theme*="dark"] .list-group * {
  background-color: transparent;
}

[data-cc-theme*="dark"] .avatars .avatar {
  border-color: #ffffff4d !important;
}

[data-cc-theme*="dark"] .avatars .avatar-active {
  border-color: #fff !important;
}

[data-cc-theme*="dark"] .iti__country-list {
  background-color: var(--bg-white);
  ;
}


.btn-secondary {
  background-color: #2f3037;
  border-color: #2f3037;
  box-shadow: unset !important;
}

/* ENDING THEMES */

/* STARTING DEFAULT STYLES */


header,
main,
footer {
  flex-shrink: 0;
}

main {
  flex-grow: 1;
}

header {
  grid-area: header;
}

main {
  grid-area: main;
}

footer {
  grid-area: footer;
}

.btn:not([class*="transparent"]):not([class*="outline"]) {
  box-shadow: 0 3px 5px 0 rgb(47 85 212 / 30%);
}

.btn.shadow-sm {
  box-shadow: 0 3px 5px 0 rgb(47 85 212 / 30%) !important;
}

[class*="theme-dark"] .btn-primary {
  color: #000 !important;
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

[class*="theme-dark"] .btn-primary:hover {
  background-color: #d9dee2;
  border-color: #d9dee2;
}

[class*="theme-dark"] .btn-primary:active {
  background-color: #b9bfc4;
  border-color: #b9bfc4
}

.theme-blue-dark .btn-transparent {
  color: #fff !important;
}

.font-light {
  font-family: "font-light" !important;
}

.font-regular {
  font-family: "font-regular" !important;
}

.font-medium {
  font-family: "font-medium" !important;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.219);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
}

.logo {
  max-width: 130px;
}

.logo img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

.nav-link:not([class*="btn"]),
.nav-link:not([class*="btn"]) *,
a:not([class*="btn"]),
a:not([class*="btn"]) {
  /* color: var(--text-main-color); */
  transition: color 0.2s ease-in-out;
}

.nav-link:not([class*="btn"]):hover,
.nav-link:not([class*="btn"]):hover *,
a:not([class*="btn"]):hover *,
a:not([class*="btn"]):hover {
  color: var(--main-hover);
}

a.active,
.nav-link:not([class*="btn"]):active,
.nav-link:not([class*="btn"]):active *,
a:not([class*="btn"]):active *,
a:not([class*="btn"]):active {
  color: var(--main-active);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h5,
.h6 {
  font-family: "font-medium";
  color: var(--text-dark-three);
  font-weight: 500;
}

code {
  font-family: "font-regular";
  color: var(--text-dark-three) !important;
}

p,
code,
span,
cite,
a,
i,
.lgg path,
label,
li {
  font-family: "font-regular";
  color: var(--text-dark-three);
}

[class*="bg-"] {
  background-size: cover !important;
}

.bg-light {
  background-color: var(--bg-light) !important;
}

.bg-light h1,
.bg-light h2,
.bg-light h3,
.bg-light h4,
.bg-light h5,
.bg-light h6,
.bg-light .h1,
.bg-light .h2,
.bg-light .h3,
.bg-light .h4,
.bg-light .h5,
.bg-light .h6,
.bg-light span,
.bg-light p,
.bg-light cite,
.bg-light i,
.bg-light a {
  color: var(--text-dark-three);
}

.bg-light .btn-primary {
  color: #fff;
}

.bg-light .nav-link:not([class*="btn"]),
.bg-light .nav-link:not([class*="btn"]) *,
.bg-light a:not([class*="btn"]),
.bg-light a:not([class*="btn"]) {
  color: var(--light-color);
  transition: color 0.2s ease-in-out;
}

.bg-light .nav-link:not([class*="btn"]):hover,
.bg-light .nav-link:not([class*="btn"]):hover *,
.bg-light a:not([class*="btn"]):hover *,
.bg-light a:not([class*="btn"]):hover,
a.link:hover {
  color: var(--light-hover);
}

.bg-light .nav-link:not([class*="btn"]):active,
.bg-light .nav-link:not([class*="btn"]):active *,
.bg-light a:not([class*="btn"]):active *,
.bg-light a:not([class*="btn"]):active,
a.link:active {
  color: var(--light-active);
}

.link,
a.link {
  color: var(--link-color) !important;
}

.link:hover,
a.link:hover {
  color: var(--link-hover) !important;
}

.link:active,
a.link:active {
  color: var(--link-active) !important;
}

.bg-dark {
  background-color: var(--bg-dark) !important;
}

.bg-dark .bg-white {
  background-color: #0a1744 !important;
}

.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark .h1,
.bg-dark .h2,
.bg-dark .h3,
.bg-dark .h4,
.bg-dark .h5,
.bg-dark .h6,
.bg-dark span,
.bg-dark p,
.bg-dark cite,
.bg-dark i,
.bg-dark a {
  color: var(--dark-color);
}

.bg-dark .nav-link:not([class*="btn"]),
.bg-dark .nav-link:not([class*="btn"]) *,
.bg-dark a:not([class*="btn"]),
.bg-dark a:not([class*="btn"]) {
  color: var(--dark-color);
  transition: color 0.2s ease-in-out;
}

.bg-dark .nav-link:not([class*="btn"]):hover,
.bg-dark .nav-link:not([class*="btn"]):hover *,
.bg-dark a:not([class*="btn"]):hover *,
.bg-dark a:not([class*="btn"]):hover {
  color: var(--dark-hover);
}

.bg-dark .nav-link:not([class*="btn"]):active,
.bg-dark .nav-link:not([class*="btn"]):active *,
.bg-dark a:not([class*="btn"]):active *,
.bg-dark a:not([class*="btn"]):active {
  color: var(--dark-active);
}

.bg-square-circle {
  /* background: url("../../../imgages/design/page-header-bg.svg") no-repeat; */
}

.bg-cyber {
  /* background: url("../../../imgages/design/shop_bg.png") no-repeat; */
}

.bg-cyber h1,
.bg-cyber h2,
.bg-cyber h3,
.bg-cyber h4,
.bg-cyber h5,
.bg-cyber h6,
.bg-cyber .h1,
.bg-cyber .h2,
.bg-cyber .h3,
.bg-cyber .h4,
.bg-cyber .h5,
.bg-cyber .h6,
.bg-cyber span,
.bg-cyber p,
.bg-cyber cite,
.bg-cyber i,
.bg-cyber a {
  color: #fff;
}

.bg-carnaval {
  /* background: url("../../../imgages/design/color-particles-1.svg") no-repeat center -6rem; */
}

.bg-dots {
  /* background-image: url('../../imgages/design/bg-dots.svg'); */
  background-size: cover;
  background-repeat: no-repeat;
}

.btn-outline-primary,
.btn-outline-skyblue {
  border: 1px solid var(--btn-primary-color);
  color: var(--btn-primary-color);
}

.btn-outline-primary:hover,
.btn-outline-skyblue:hover,
.btn-primary,
.btn-skyblue {
  background-color: var(--btn-primary-color) !important;
  border: 1px solid var(--btn-primary-color) !important;
  color: white !important;
}

.btn-primary:hover,
.btn-skyblue:hover {
  background-color: var(--btn-primary-hover) !important;
  border-color: var(--btn-primary-hover) !important;
}

.btn-outline-primary:active,
.btn-outline-skyblue:active,
.btn-primary:active,
.btn-skyblue:active {
  background-color: var(--btn-primary-active) !important;
  border-color: var(--btn-primary-active) !important;
}

.btn * {
  color: inherit !important;
}

.navbar-nav .nav-link a,
.navbar-nav .nav-link * {
  transition: color 0.2s ease-in-out;
}

.icon-list li {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  background-color: rgba(163, 163, 163, 0.226);
  transition: background-color 0.2s ease-in-out;
}

.icon-list li:hover {
  background-color: rgba(163, 163, 163, 0.459);
  cursor: pointer;
}

.icon-list-img li {
  padding: unset;
  margin: unset;
  width: 50px;
  height: auto;
  background-color: unset;
  transition: unset;
}

.icon-list-img li:hover {
  cursor: auto;
}

.icon-list-img li img {
  width: 100%;
  height: 100%;
}

[class*="icon-list light"] li {
  background-color: #7489c438;
}

[class*="icon-list light"] li * {
  color: #192a56;
}

[class*="icon-list light"] li:hover {
  background-color: #7489c47c;
}

.hr::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 50px;
  height: 3px;
  background: linear-gradient(95.45deg, #517ccf 0.64%, #24498f 98.23%);
}

.dropdown .dropdown-toggle::after {
  border: solid var(--text-dark-one);
  border-width: 0 1.9px 1.9px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 8px;
  transition: 0.2s;
  position: relative;
}

.dropdown .dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(225deg);
  top: 4px;
}

.bg-test {
  background-image: url('./images/design/section_pattern.png'),
    -webkit-linear-gradient(top, #050e7f 0%, #2f57a4 100%);
}

.bg-test span {
  color: white !important;
}

.bg-test p {
  color: white !important
}

.bg-test .btn-outline-primary {
  color: white !important;
}

.dropup .dropdown-toggle::after {
  border: solid var(--text-dark-one);
  border-width: 0 1.9px 1.9px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  margin-left: 8px;
  transition: 0.2s;
  position: relative;
  top: 4px;
}

.dropup .dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(45deg);
  top: 0px;
}

.title {
  font-size: 16.2px;
  font-weight: 500;
}

.contact-info {
  padding: 40px 18px 40px 18px;
}

.contact-info>.container div {
  word-wrap: break-word;
}



.contact-info .title {
  font-size: 20px;
}

.contact-info cite {
  font-style: normal;
}

.contact-info .title {
  font-size: 26px;
}

.countries {
  width: max-content !important;
  padding: 10px !important;
  flex-direction: column;
}

.countries li {
  width: 100% !important;
  transition: transform 0.1s;
}

.countries .active * {
  color: var(--active-color-style-one);
}

.countries li:hover {
  transform: scale(1.020);
  color: var(--text-dark-one);
}

.countries a {
  text-decoration: none;
}

.countries .flag,
.countries .flag {
  max-width: 34px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countries .flag img {
  width: 100%;
  height: auto;
}

.bg-separator {
  background-color: var(--bg-light) !important;
}

[class*="collapse navbar-collapse show"] {
  right: 0 !important;
  z-index: 10;
}

.collapse-horizontal {
  width: 100% !important;
}

.collapsing.collapse-horizontal,
.collapse-horizontal.collapsing,
.collapse-horizontal.show {
  transition: right 0.1s ease-in-out;
}

.collapsing.collapse-horizontal {
  right: -100% !important;
}

.collapse-horizontal.show {
  right: 0px !important;
}

.collapse-horizontal.show .btn {
  margin: unset !important;
}

.currency,
.countries {
  width: 280px !important;
}

.collapse-horizontal.show .countries {
  padding: unset;
}

header .countries .nav-item {
  padding: 5px;
}

header .countries .nav-item .flag {
  max-width: 24px !important;
}

.btn * {
  color: inherit;
}

.btn-primary {
  background-color: #175cff;
  border-color: #175cff;
}

.btn-outline-primary:hover {
  background-color: #175cff;
  border-color: #175cff;
}

.content-img {
  width: 100%;
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-profile {
  max-width: 100px;
}

.content-img.min {
  max-width: 120px !important;
}

.content-img.xxs {
  max-width: 140px !important;
}

.content-img.xs {
  max-width: 240px !important;
}

.content-img.sm {
  max-width: 424px !important;
}

.content-img.md {
  max-width: 480px !important;
}

.content-img.lg {
  max-width: 580px !important;
}

.content-img.xl {
  max-width: 680px !important;
}

.content-img img {
  width: 100%;
  height: auto;
}

.content-img.auto {
  max-width: 100% !important;
}

.content-img.icon {
  max-width: 80px !important;
}

.content-img.icon.min {
  max-width: 24px !important;
}

.content-img.icon.xs {
  max-width: 40px !important;
}

.content-img.icon.sm {
  max-width: 50px !important;
}

.content-img.icon.md {
  max-width: 60px !important;
}

.content-img.icon.lg {
  max-width: 70px !important;
}

.content-img.icon.xl {
  max-width: 100px !important;
}

[class*="hover-"],
[class*="hover-bg-icon"] i,
[class*="hover-brightness"],
.hover-shadow,
.hover-scale,
.hover-bend {
  transform-origin: background-color, filter, box-shadow, -webkit-box-shadow, scale, bend;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.hover-bg-white:hover {
  background-color: #fff;
}

.hover-bg-icon-white:hover i {
  background-color: #fff !important;
  color: var(--text-dark-two) !important;
}

.hover-brightness-light {
  filter: brightness(0.8);
}

.hover-brightness-light:hover {
  filter: unset;
}

.hover-brightness-dark:hover {
  filter: brightness(0.8);
}

.hover-shadow:hover {
  -webkit-box-shadow: 0 20px 20px 0 rgb(2 19 79 / 10%);
  box-shadow: 0 20px 20px 0 rgb(2 19 79 / 10%);
}

.hover-scale:hover {
  scale: 1.1;
}

.hover-bend:hover {
  rotate: 3deg;
}

.hover-bend.reverse:hover {
  rotate: -3deg;
}

.hover-white:hover {
  color: #fff;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-muted {
  color: var(--text-muted-color) !important;
}

.text-md {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
}

.link-arrow {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.link-arrow i {
  margin-left: 6px;
  font-size: 20px;
  transition: margin-left 0.2s ease-in-out;
}

.link-arrow:hover i {
  margin-left: 10px;
}

i.rounded,
i.squared {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 29px;
}

.icon-rounded-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 29px;
  width: 60px !important;
  height: 60px !important;
  border-radius: 5px;
}

i.rounded {
  width: 60px;
  height: 60px;
  border-radius: 100% !important;
}

i.squared {
  width: 54px;
  height: 54px;
  border-radius: 6px !important;
}

i.sm {
  width: 24px;
  height: 24px;
  font-size: 12px;
}

i.md {
  width: 24px;
  height: 24px;
  font-size: 20px;
}

i.lg {
  width: 65px;
  height: 65px;
  font-size: 36px;
}

i.xl {
  width: 85px;
  height: 85px;
  font-size: 45px;
}

i.xxl {
  width: 120px;
  height: 120px;
  font-size: 60px;
}

.bg-blue-light {
  background-color: var(--bg-blue-light) !important;
}

.text-success {
  color: var(--text-success) !important;
}

.text-black {
  color: var(--text-black) !important;
}

.text-blue {
  color: var(--text-blue) !important;
}

.text-blue-dark {
  color: var(--text-blue-dark) !important;
}

.bg-indigo-light {
  background-color: var(--bg-indigo-light) !important;
}

.text-indigo {
  color: var(--text-indigo) !important;
}

.bg-purple-light {
  background-color: var(--bg-purple-light) !important;
}

.text-purple {
  color: var(--text-purple) !important;
}

.bg-pink-light {
  background-color: var(--bg-pink-light) !important;
}

.text-pink {
  color: var(--text-pink) !important;
}

.bg-red-light {
  background-color: var(--bg-red-light) !important;
}

.text-red {
  color: var(--text-red) !important;
}

.bg-orange-light {
  background-color: var(--bg-orange-light) !important;
}

.text-orange {
  color: var(--text-orange) !important;
}

.bg-yellow-light {
  background-color: var(--bg-yellow-light) !important;
}

.text-yellow {
  color: var(--text-yellow) !important;
}

.bg-green-light {
  background-color: var(--bg-green-light) !important;
}

.text-green {
  color: var(--text-green) !important;
}

.bg-teal-light {
  background-color: var(--bg-teal-light) !important;
}

.text-teal {
  color: var(--text-teal) !important;
}

.bg-cyan-light {
  background-color: var(--bg-cyan-light) !important;
}

.text-cyan {
  color: var(--text-cyan) !important;
}

.bg-grey-light {
  background-color: var(--bg-grey-light) !important;
}

.text-grey {
  color: var(--text-grey) !important;
}

.side-by-side>* {
  margin-bottom: 100px !important;
}

.side-by-side.near>* {
  margin-bottom: 50px !important;
}

.side-by-side>*:nth-child(odd)>*:nth-child(even) {
  margin-left: 100px !important;
  align-items: flex-end;
}

.side-by-side>*:nth-child(even) {
  flex-direction: row-reverse;
}

.side-by-side>*:nth-child(even)>*:nth-child(odd) {
  margin-left: 100px !important;
  align-items: flex-end;
}

.side-by-side-img {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-by-side .content-img {
  padding: 15px;
}

.side-by-side-img .position-absolute.top {
  top: -53px !important;
}

.side-by-side-img .position-absolute.bottom {
  bottom: -53px !important;
}

.side-by-side-img .position-absolute.left {
  left: -53px !important;
}

.side-by-side-img .position-absolute.right {
  right: -60px !important;
}

.bg-style-one {
  background-color: var(--thumb-bg-one) !important;
}

.border-light {
  border: 1px solid rgb(241, 241, 241) !important;
}

.border-top-light {
  border-top: 1px solid rgb(241, 241, 241) !important;
}

.border-bottom-light {
  border-bottom: 1px solid rgb(241, 241, 241) !important;
}

.border-left-light {
  border-left: 1px solid rgb(241, 241, 241) !important;
}

.border-right-light {
  border-right: 1px solid rgb(241, 241, 241) !important;
}

.carousel-indicators {
  margin: unset !important;
  padding: unset !important;
}

.carousel-indicators [data-bs-target] {
  background-color: var(--main-active);
}

[class*="dark"] .carousel-indicators [data-bs-target] {
  background-color: #fff !important;
}

.thumb[class*="dark"] .carousel-indicators [data-bs-target] {
  background-color: inherit;
}

.watch-video-play {
  width: max-content !important;
  background-color: transparent;
  border: none;
  outline: none;
  filter: unset !important;
}

.side-by-side-choose>*:nth-child(odd)>*:nth-child(even) {
  margin-left: unset !important;
  align-items: unset;
}

.side-by-side-choose>*:nth-child(even) {
  flex-direction: unset;
}

.side-by-side-choose>*:nth-child(even)>*:nth-child(odd) {
  margin-left: unset !important;
  align-items: unset;
}

.side-by-side-choose .side-by-side-img {
  background-color: transparent !important;
  position: relative;
  top: 24px;
  right: 0;
  justify-content: flex-start;
}

.side-by-side-choose .side-by-side-img img {
  filter: brightness(65%);
}

.side-by-side-choose .bg-dots {
  background-position: 44px 0;
}

.video-embed {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.596);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-embed .embed-responsive {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  max-height: 700px;
}

.video-embed .embed-responsive .embed-responsive-item {
  width: 100%;
  height: 100%;
}

.video-embed button {
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 24px;
}

.video-embed button i {
  color: rgb(218, 214, 214);
}

.rates>i {
  color: #ffb116;
}

.avatars {
  display: flex;
  overflow: hidden;
}

.avatars .avatar {
  width: 100%;
  padding: 13px;
}

.avatars .avatar {
  word-break: break-all;
  padding: 11px !important;
}

.avatar .content-img {
  min-width: 40px;
  max-width: 50px;
}

.avatar .content-img img {
  border-radius: 100%;
}

.avatars .avatar-active {
  border: 1px solid #0d289452 !important;
}

.avatars .owl-item .avatar {
  padding: unset;
}

#main {
  margin-top: 75px !important;

}

.w-mc {
  width: max-content !important;
}

.h-mc {
  height: max-content !important;
}

.slick-track {
  display: flex;
}

.table-responsive>table {
  min-width: 960px !important;
  color: var(--text-dark-three);
}

.fixed-top {
  position: fixed;
  top: -100%;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: top 0.3s ease-in-out;
  border-bottom: var(--border-grey-light);
}

.fixed-top-dashboard {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.up-to-top {
  min-width: 40px;
  padding: 10px;
  position: fixed;
  right: 24px;
  bottom: 70px;
  z-index: 10;
}

.up-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-blue);
  color: #fff;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-page .spinner-border {
  margin-right: 10px;
}

.loading-page .loading-title {
  margin: unset;
  font-size: 22px;
}

.domain {
  max-width: 900px;
  text-align: center;
}

.input-group-row [type*="submit"],
.domain form [type*="submit"] {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

.tlds {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tlds .tld {
  width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.tlds .tld .content-img {
  width: 80px;
}

.tlds .tld cite {
  font-family: "font-bold";
  margin-top: 4px;
}

.contract {
  word-break: break-word;
}

.contract-documents a {
  color: var(--link-color);
}

.contract-documents ul li,
.list-with-bullets li {
  list-style-type: disc;
}

.btn-transparent:hover {
  border: 1px solid #dee2e6;
}

.popover {
  background-color: var(--bg-white);
  color: var(--text-dark-three);
  border: var(--border-style-one);
  font-family: "font-regular";
}

.popover * {
  color: var(--text-dark-three);
}

.hover-scale {
  transition: transform 0.2s ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.02, 1.02);
  -webkit-transform: scale(1.02, 1.02);
}

.lead-title,
.lead-text,
.lead-desc {
  width: 75%;
}

.lead-title {
  margin: 0 auto 1rem auto;
}

.lead-title.lead-text,
.lead-text {
  margin: 0 auto 2.7rem auto;
}

.lead-desc {
  margin-left: auto;
  margin-right: auto;
}

#dark-mode,
#dark-mode i {
  display: flex;
  align-items: center;
  justify-content: center;
}

#dark-mode {
  background-color: var(--btn-primary-color);
  border-color: var(--btn-primary-color);
  color: #fff;
  position: fixed;
  right: -100px;
  top: 20%;
  border-radius: 20px 0 0 20px;
  transition: right 0.2s ease-in-out;
  z-index: 11;
}

#dark-mode:hover {
  right: 0;
}

.alert button i {
  font-size: 13px;
}

.alert button {
  outline: none !important;
  border: none !important;
  box-shadow: unset !important;
}

.alert button:hover {
  outline: none !important;
  border: none !important;
}

/* ENDING DEFAULT STYLES */

/* STARTING HEADER STYLES */

.header>.container {
  padding: unset !important;
}

[class*="header-"] {
  border-bottom: var(--border-style-one);
  padding: 5px 0px 5px 0px;
}

.header-one {
  background-color: var(--header-one-bg);
}

.header-one .d-flex>div:first-child {
  width: 100%;
  max-width: 430px;
}

.header-one .d-flex>div:last-child {
  width: 100%;
}

.header-one .navbar-toggler i {
  font-size: 25px !important;
}

.header-one .d-flex>div:first-child p {
  word-break: break-all;
}

header .header-one *,
header .header-two * {
  color: var(--header-one-color);
}

.header-two {
  background-color: var(--header-two-bg);
  padding: 18px 0px 18px 0px;
}

.header-two .nav-item>.nav-link {
  font-family: "font-medium";
  padding-left: unset !important;
  padding-right: unset !important;
  padding: 6px;
  margin-left: 18px;
}

.header-one *,
.nav-link * {
  font-size: 0.9375rem;
  font-weight: 500;
}

header ul {
  list-style: none;
}

header ul a * {
  transition: 0.2s ease-in-out;
}

.header-one .dropdown-toggle,
.header-two .dropdown-toggle {
  transition: border-color 0.2s ease-in-out;
}

.header-one .dropdown-menu {
  background-color: var(--header-one-bg);
}

.header-one a:hover *,
.header-one .dropdown-menu .nav-link:hover * {
  color: var(--header-one-hover-color);
}

.header-one a:active *,
.header-one .dropdown-menu .nav-link:hover * {
  color: var(--header-one-active-color);
}

.header-one .dropdown-toggle:hover::after {
  border-color: var(--header-one-hover-color);
}

.header-one .dropdown-toggle:active::after {
  border-color: var(--header-one-active-color);
}

.header-two .dropdown-menu {
  background-color: var(--header-two-bg);
}

.header-two a:hover *,
.header-two .dropdown-menu .nav-link:hover * {
  color: var(--header-two-hover-color);
}

.navbar-nav .nav-link.show *,
.navbar-nav .nav-link.active * {
  color: var(--header-two-active-color) !important;
}

.navbar-collapse.collapsing .navbar-nav .nav-link.active *,
.navbar-collapse.show .navbar-nav .nav-link.active * {
  color: #fff !important;
}

.navbar-collapse.collapsing .dropdown-toggle.active:after,
.navbar-collapse.show .dropdown-toggle.active:after {
  border-color: #fff !important;
}


.dropdown-toggle.active:after,
.dropdown-toggle.show:after,
.dropdown-toggle[aria-expanded="true"].show:after,
.header-two .dropdown-toggle:hover::after {
  border-color: var(--header-two-hover-color);
}

.navbar-nav .dropdown-toggle.show::after,
.navbar-nav .dropdown-toggle.active::after,
.header-two .dropdown-toggle:active::after {
  border-color: var(--header-two-active-color);
}

header .dropdown-modern {
  right: 0;
}

.owl-stage {
  display: flex;
}

.owl-nav.disabled {
  display: none;
}

/* ENDING HEADER STYLES */


/* STARTING THUMB */

section,
.thumb {
  background-color: var(--thumb-bg-one);
  padding-top: 40px;
  padding-bottom: 40px;
}

section>.container,
.thumb>.container {
  align-items: center;
}

.thumb .btn-group {
  width: auto;
  flex-wrap: wrap;
}

.thumb-modern {
  display: flex;
  align-items: center;
  justify-content: center;
}

[class*="thumb-modern bg"] {
  display: block;
  /* background: url("../../../imgages/design/color-particles-1.svg") no-repeat center -6rem; */
}

.thumb-modern .container {
  margin: 30px auto 30px auto !important;
}

.thumb-modern>.container p {
  max-width: 760px;
}

/* ENDING THUMB */

/* STARTING PREVIEW */

.preview {
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.preview [class*="content-img main"] {
  max-width: 1000px;
}

.preview [class*="content-img feature"] {
  width: max-content;
  position: absolute;
}

.preview [class*="content-img feature"]:first-child {
  left: -90% !important;
  top: 70px !important;
}

.preview [class*="content-img feature"]:last-child {
  left: 130% !important;
  top: unset !important;
  bottom: 70px !important;
}

#preview-features {
  width: 400px;
  height: 100%;
  position: absolute;
}

/* ENDING PREVIEW */

/* STARTING NAVBAR STYLES */

header .navbar-nav .nav-item>a {
  color: var(--text-dark-one);
}

header .navbar-nav {
  flex-wrap: wrap;
}

nav .dropdown-modern-sample {
  width: max-content !important;
  padding: 16px !important;
  border: var(--border-style-two);
}

[class*="drop"] .dropdown-modern {
  width: 720px;
  padding: 30px;
  border: var(--border-style-two);
  display: none;
  flex-wrap: wrap;
  left: auto;
  justify-content: flex-start;
}

.navbar .collapsing,
[class*="navbar-collapse collapse show"] {
  z-index: 12;
}

.dropdown-column {
  width: auto !important;
  padding: 15px !important;
}

[class*="drop"] [class*="dropdown-menu dropdown-modern show"] {
  display: flex !important;
}

.dropdown-modern {
  justify-content: center;
}

.dropdown-modern>li,
.nav-modern>li {
  width: 217px;
  padding: 12px;
}

.nav-modern ul {
  list-style-type: none;
}

.dropdown-modern li>span,
.nav-modern li>span {
  font-size: 16.2px;
  font-weight: 500;
}

.dropdown-modern li {
  color: var(--bs-body-color);
}

.dropdown-modern li>ul>li,
.nav-modern li ul>li {
  font-size: 16.4px;
  padding: 4px;
}

nav .dropdown-modern-sample a,
.dropdown-modern li>ul li a,
.nav-modern li ul li a {
  text-decoration: none;
}

.dropdown-modern li>ul li a {
  text-decoration: none;
  color: var(--bs-body-color);
}

.dropdown-modern li ul li:hover * {
  transition: 0.2s ease-in-out;
}

/* .dropdown-modern li .nav-link {
  padding-left: 6px !important;
  padding-right: 6px !important;
} */

.dropdown-modern ul,
.nav-modern ul {
  padding: unset;
  margin: unset;
}

.navbar-collapse .navbar-collapse-header {
  display: none;
}

.navbar-collapse .logo {
  max-width: 120px;
}

header [class*="collapsing"] .navbar-collapse-header,
header [class*="collapse show"] .navbar-collapse-header {
  display: flex;
}

ul li {
  list-style-type: none;
}

/* ENDING NAVBAR STYLES */

/* STARTING FORM */

.form-box {
  max-width: 460px;
  padding: 35px;
}

.form-box textarea {
  resize: none;
}

.iti__selected-flag {
  padding: 5px 6px 0 8px !important;
}

.iti {
  display: block !important;
}

.iti {
  width: 100%;
}

.iti__flag-container {
  right: 0 !important;
}

.iti--allow-dropdown input {
  width: 100%;
}

.country-dropdown {
  width: 100% !important;
}

.iti__country-list {
  width: 100%;
}

.iti__country-name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ENDING FORM */

/* STARTING BLOG */

/* .blog > [class*="col"] > .d-flex {
  max-width: 416px;
  margin-left: auto;
} */

.post-preview span.h6,
.post-preview p {
  max-width: 260px;
}

.post-preview span.h6 {
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.post-preview p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.post-preview .content-img {
  max-width: 160px;
}

.post-preview .content-profile {
  max-width: 60px;
}

#comments .post-preview span.h6,
#comments .post-preview p {
  -webkit-line-clamp: 2;
}

.blog .navigation-links li {
  border: 1px solid transparent;
}

.slider-thumbnail {
  height: 222px;
}

.slider-thumbnail img {
  width: 100%;
  height: 100%;
}

.blog .title-preview,
.blog .text-preview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.blog .title-preview {
  -webkit-line-clamp: 2;
}

.blog .text-preview {
  min-height: 120px;
  margin-top: unset;
  margin-left: unset;
  margin-right: unset;
  -webkit-line-clamp: 5;
}

.blog .owl-carousel.main-slider .text-preview {
  min-height: 70px;
  -webkit-line-clamp: 3;
}

.blog .owl-carousel.main-slider .title-preview {
  -webkit-line-clamp: 1;
}

.blog article .badge {
  font-size: 14px;
}

/* ENDING BLOG */

/* STARTING PRICES */

.price .title {
  font-size: 15px;
  font-family: "font-medium";
}

.price .price-header .title {
  font-size: 16px;
}

.price p {
  font-size: 15px;
  font-weight: 400;
}

.price .price-feature {
  text-align: start;
}

.price .price-feature i {
  font-size: 18.5px;
}

.price-info {
  font-size: 24px;
}

.price-info small {
  font-size: 16px;
}

.price-in-row>[class*="col"]>div {
  border-right-width: 0px !important;
}

.price-in-row .price-start {
  min-width: 240px;
  border-right-width: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.price-in-row .price-end {
  min-width: 280px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.price-in-row .price-features {
  display: flex;
  flex-wrap: wrap;
}

.price-in-row .price-features>.price-feature {
  flex: 5 0 auto;
  width: 33.33333333%;
}

.price-in-row * {
  text-align: start;
}

.price-in-row .title-main {
  font-size: 17px;
  font-family: "font-medium";
}

.price-advanced {
  text-align: start;
}

.price-advanced [class*="col"]>div {
  padding: 24px !important;
}

.price-advanced ul li span {
  font-size: 14px;
  font-family: "font-medium";
}


.price-advanced [class*="bi-check"],
.price-advanced [class*="bi-x"],
.price-advanced [class*="bi-question"] {
  border-radius: 100%;
  font-size: 14px;
  padding: 1px;
}


.price-advanced [class*="bi-check"],
.price-advanced [class*="bi-x"] {
  color: #fff;
}

.price-advanced [class*="bi-check"] {
  background-color: #00b090;
}


.price-advanced [class*="bi-x"] {
  background-color: #fc5185;
}

.price-advanced [class*="bi-question"] {
  background-color: var(--bg-grey);
  border: 1px solid #d2d9df8c;
}

.price-table {
  border-radius: 10px !important;
}

.price-table [class*="bi-check"],
.price-table [class*="bi-x"],
.price-table [class*="bi-question"] {
  font-size: 16px;
}

.price-table [class*="bi-check"] {
  color: #00b090;
}

.price-table [class*="bi-x"] {
  color: #fc5185;
}

.price-table th,
.price-table td {
  padding: 24px;
}

.table-striped.price-table>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bg-light) !important;
}

.price-sample .title {
  font-size: 30px;
}

.price-sample [class*="bi-check"],
.price-sample [class*="bi-x"] {
  font-size: 20px;
}

.price-sample [class*="bi-check"] {
  color: green !important;
}

.price-sample [class*="bi-x"] {
  color: red !important;
}

/* ENDING PRICES */

/* STARTING COOKIE INFO */

.cookies-info {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: bottom 0.2s ease-in-out;
}

.cookies-info>.cookies-info-container {
  width: 100%;
  max-width: 1000px;
  background-color: var(--bg-grey);
  border: 1px solid var(--bs-border-radius);
  border-radius: 4px;
  padding: 20px;
}

.cookies-info.active {
  bottom: 30px;
}

/* ENDING COOKIE INFO */

/* STARTING LOADING PAGE */

.loading-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #040d2e;
  color: #fff;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-page .logo {
  max-width: 140px;
}

.loading-page .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-page .lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-page .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.loading-page .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loading-page .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loading-page .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* ENDING LOADING PAGE */

/* STARTING RESPONSIVE STYLES */

@media (max-width: 1300px) {

  .side-by-side-img .position-absolute.right {
    right: 0 !important;
  }

}

@media (max-width: 1399px) {

  header .dropdown-modern {
    width: 500px !important;
    right: unset !important;
    left: 0 !important;
  }

  .side-by-side-img .position-absolute.right {
    right: -40px !important;
  }

  .price-in-row .price-features>.price-feature {
    width: 50%;
  }

  .price-in-row .price-features>.price-feature:nth-last-child(3) {
    border-width: 1px !important;
  }

}

@media (max-width: 1199px) {

  .slider-thumbnail {
    height: 160px;
  }

  .side-by-side-img {
    width: 100%;
  }

  .bg-img-md-none {
    background-image: unset !important;
  }

  .side-by-side-choose .side-by-side-img {
    top: unset !important;
    right: unset !important;
    margin: 0 auto;
  }

  .side-by-side-choose .side-by-side-img .content-img {
    max-width: 100% !important;
  }

  .form-box {
    max-width: 680px;
  }

  .header-one {
    padding: 0.6rem 0 0.6rem 0;
  }

  .collapse-horizontal {
    overflow-x: auto;
    z-index: 12;
  }

  .collapse-horizontal>.row:not([class*="navbar-collapse-header"]) {
    margin: unset !important;
    flex-direction: column;
  }

  header .nav-link *,
  header .nav-link,
  header .collapse-horizontal .btn {
    color: #fff !important;
  }

  header .collapse-horizontal .btn-transparent {
    border: 1px solid rgba(255, 255, 255, 0.493);
  }

  header .navbar-collapse .nav-item>.nav-link {
    padding-left: unset !important;
    padding-right: unset !important;
    margin-left: unset;
    text-align: start;
  }

  header .navbar-collapse .nav-item {
    margin: unset !important;
  }

  header .navbar-collapse .navbar-nav .nav-item-btn:nth-last-child(2) {
    margin-top: 12px !important;
  }

  header .navbar-collapse .navbar-nav .nav-item-btn:nth-last-child(1) {
    margin-top: 6px !important;
  }

  .navbar-toggler {
    border: unset;
    padding: unset;
    margin: unset;
    font-size: 34px;
    outline: none !important;
    box-shadow: unset !important;
  }

  .navbar-toggler * {
    transition: color 0.2s ease-in-out;
  }
  .navbar-toggler-sub {
    color: white !important;
  }

  .navbar-toggler:hover * {
    color: var(--hover-color-style-one);
  }

  .navbar-toggler:active * {
    color: var(--active-color-style-one);
    color: white !important;

  }

  .navbar-collapse-header .navbar-toggler {
    font-size: 25px;
  }

  .navbar-collapse-header .navbar-nav .nav-link.active *,
  .navbar-collapse.show .navbar-nav .nav-link.show *,
  .navbar-collapse.show .navbar-nav .nav-link.active *,
  .navbar-collapse-header .navbar-toggler:hover *,
  .navbar-collapse.show .nav-link:hover * {
    color: #fff !important;
  }

  .navbar-collapse-header .navbar-toggler.show:active *,
  .navbar-collapse.show .nav-link.show:active *,
  .navbar-collapse-header .navbar-toggler.active:active *,
  .navbar-collapse.show .nav-link.active:active *,
  .navbar-collapse-header .navbar-toggler:active *,
  .navbar-collapse.show .nav-link:active * {
    color: var(--active-color-style-three) !important;
  }

  .navbar .collapsing .dropdown-toggle::after,
  .navbar .navbar-collapse .dropdown-toggle::after {
    border-color: #fff;
  }

  .navbar .collapsing .dropdown-toggle.active::after,
  .navbar .navbar-collapse .dropdown-toggle.active::after,
  .navbar .collapsing .dropdown-toggle.show::after,
  .navbar .navbar-collapse .dropdown-toggle.show::after,
  .navbar .collapsing .dropdown-toggle:hover::after,
  .navbar .navbar-collapse .dropdown-toggle:hover::after {
    border-color: #fff;
  }

  .navbar .collapsing .dropdown-toggle.active:active::after,
  .navbar .navbar-collapse .dropdown-toggle.active:active::after,
  .navbar .collapsing .dropdown-toggle:active::after,
  .navbar .navbar-collapse .dropdown-toggle:active::after {
    border-color: var(--active-color-style-three) !important;
  }

  .navbar-collapse .dropdown-column {
    padding: unset !important;
  }

  .header-one .navbar {
    width: unset;
    margin: unset !important;
  }

  .header-two .container .row>[class*="col"] {
    width: max-content !important;
  }

  .header-two .container .row>[class*="col"]:last-child {
    margin-left: auto;
  }

  header .navbar-collapse.show .dropdown .dropdown-modern {
    width: 100%;
    flex-direction: column;
    padding: unset;
    border: unset;
  }

  header .navbar-collapse.show .navbar .navbar-toggler {
    box-shadow: unset;
    border: none;
    transition: 0.2s ease-in-out;
    font-size: 40px;
    padding: unset;
  }

  header .navbar-collapse.show .navbar .navbar-toggler * {
    color: var(--bs-body-color);
    transition: color 0.1s ease-in-out;
  }

  header .navbar-collapse.show .navbar .navbar-toggler *:hover {
    color: var(--hover-color-style-one);
  }

  header .navbar-collapse.show .navbar .collapsing .navbar-toggler *,
  header .navbar-collapse.show .navbar .navbar-collapse .navbar-toggler * {
    color: #fff;
    transition: color 0.1s ease-in-out;
  }

  header .navbar-collapse.show .navbar .collapsing .navbar-toggler *:hover,
  header .navbar-collapse.show .navbar .navbar-collapse .navbar-toggler *:hover {
    color: #fff;
  }

  header .navbar-collapse.show .navbar .collapsing .navbar-toggler *:active header .navbar-collapse.show .navbar .navbar-collapse .navbar-toggler *:active {
    color: var(--active-color-style-three);
  }

  b.navbar-collapse .navbar-collapse-header button {
    color: #fff;
    font-size: 24px;
  }

  .navbar .container-fluid {
    justify-content: flex-end !important;
  }

  .navbar-collapse {
    width: 100%;
    max-width: 280px;
    padding: 15px;
    background-color: #001a2c;
    position: fixed;
    top: 0;
  }

  .collapse-horizontal,
  .collapse-horizontal.collapsing,
  .collapse-horizontal.show {
    height: 100vh;
    overflow-x: hidden;
  }

  .navbar-collapse span,
  .navbar-collapse i,
  .navbar-collapse .navbar-nav .dropdown-menu *,
  .navbar-collapse .navbar-nav .dropdown *,
  .navbar-collapse .navbar-nav .nav-link {
    background-color: transparent;
    color: #fff;
  }

  .navbar-collapse .navbar-nav {
    width: 100%;
    margin-top: 10px;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    text-align: start !important;
  }

  .navbar-collapse .navbar-nav .nav-link:hover *,
  .navbar-collapse .navbar-collapse-header button:hover {
    transition: 0.2s ease-in-out;
    color: #fff;
  }

  .navbar-collapse .navbar-nav .nav-link:active *,
  .navbar-collapse .navbar-collapse-header button:active {
    transition: 0.2s ease-in-out;
    color: var(--active-color-style-three);
  }

  .navbar-collapse .navbar-nav .active * {
    color: #fff;
  }

  .dropdown .dropdown-modern-sample {
    padding: 0px 8px 0 8px !important;
    border: unset;
  }

  header .countries {
    padding: 2px 8px 2px 8px !important;
  }

  header .countries .nav-item {
    padding: 0.5rem 0 0.5rem 0;
  }

}