#footer {
    /* margin-top: 25px; */
    background-image: url('../../images/design/section_pattern.png'), 
    -webkit-linear-gradient(top, #050e7f 0%,#2f57a4 100%);
    color: #fff !important;
    font-size: 14px
}

#footer p {
    color: white !important;
}

#footer .footer-newsletter {
    padding: 50px 0;
    border-top: 1px solid white;
    /* background: #2f57a4 */
}

#footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    color: white;
    line-height: 1;
    font-weight: 600
}

#footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px
}

#footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 8px;
    width: calc(100% - 140px)
}

[data-cc-theme*="dark"] #footer .footer-newsletter form input[type="submit"] {
    color: white !important;
}

#footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 30px;
    margin: 3px;
    background: #150abc;
    /* color: #fff; */
    transition: 0.3s;
    border-radius: 50px
}

#footer .footer-newsletter form input[type="submit"]:hover {
    background: #2f57a4;

}

#footer input[type="submit"] {
    color: white;
}

#footer .footer-top {
    /* background-image: linear-gradient(-90deg, #150abc 0%, #2f57a4 100%); */
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 60px 0 30px 0
}

#footer .footer-top .footer-info {
    margin-bottom: 30px
}

#footer .footer-top .footer-info h3 {
    font-size: 18px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #545454;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s
}
#footer .footer-top .social-links a[class="nohover"] {
    font-size: 18px;
    display: inline-block;
    /* background: #545454; */
    background-color: transparent !important;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s
}

#footer .footer-top .social-links a:hover {
    background: #2f57a4;
    color: #fff !important;
    text-decoration: none
}

#footer .footer-top h3 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px
}

#footer .footer-top .footer-links {
    margin-bottom: 30px
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ec7f6d;
    font-size: 18px;
    line-height: 1
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1
}

#footer .footer-top .footer-links ul a:hover {
    color: lightslategray;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px
}

#footer .footer-top .footer-contact p {
    line-height: 26px
}

#footer .copyright {
    border: top 1px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

#footer .copyright span {

    color: white;
}

#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff
}

.trustpilot-widget {
    border-radius: 5px;
}
.trustpilot-widget:hover{
    background-color: #2f57a4 !important;
    cursor: pointer;
}
[data-cc-theme*="dark"] #translations-main {
    color: white !important
}